import { createStore, compose, applyMiddleware } from 'redux';
// import { createEpicMiddleware } from 'redux-observable';
// import { rootEpic } from './reducers/rootReducer'
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
// const epicMiddleware = createEpicMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
  return createStore(rootReducer, initialState, composeEnhancer(applyMiddleware(thunk)));
}
