import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header } from '.';
import { connect } from 'react-redux';
import { fetchUser } from '../../../actions/AuthActions';
// import {
//   DYNAMICS_CLIENT_ID,
//   DYNAMICS_GRANT_TYPE,
//   DYNAMICS_CLIENT_SECRET,
//   APP_DYNAMICS_RESOURCE,
// } from '../../../utils/constants';
import Loader from 'react-loader-spinner';
import toBoolean from 'validator/es/lib/toBoolean';

class UserMain extends React.Component {
  state = {
    isLoader: null,
    sidebarStatus: true
  };

  // componentWillMount() {
  //   // var backend_token = localStorage.getItem('token');
  //   // var payload = { token: backend_token };
  //   // this.props.fetchUserFn(payload);
  // }

  checkIsLoader = (isLoader) => {
    if (isLoader && isLoader === true) {
      return (
        <div className="loader_div">
          <div className="inner-loader">
            <Loader
              className="inner-shadow"
              visible={isLoader}
              type="Puff"
              color="#8AC53A"
              height={100}
              width={100}
              // timeout={3000} //3 secs
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  sidebarStatus = async(sbStatus) =>{
          
    await this.setState({sidebarStatus: sbStatus});
   // console.log("Status",this.state.sidebarStatus);
  }

  render() {
    const { children } = this.props;
    const isLoader = toBoolean(localStorage.getItem('loader'));
    return (
      <div className={"user-dashboard " + (this.state.sidebarStatus ? "sidebar-open" : "sidebar-collapse")}>
        {this.checkIsLoader(isLoader)}
        <Header />
        {/* <SideBar status={this.sidebarStatus}/> */}
        <section>{children}</section>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => ({
  fetchUserFn: (user) => dispatch(fetchUser(user)),
});

export default withRouter(connect(null, mapDispatch)(UserMain));
