import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Wrapper from './components/HOC/Wrapper';
import RequireAuth from './components/HOC/RequireAuth';
import {
  Landing,
  PageNotFound
} from './components/pages';

import { EventsList, EventDetail } from './components/pages/Agency/EventsManagement';

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Wrapper(Landing)} />
          <Route exact path="/events" component={Wrapper(RequireAuth(EventsList))} />
          <Route exact path="/event-detatil/:id" component={Wrapper(RequireAuth(EventDetail))} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    );
  }
}
