/*eslint eqeqeq: "off"*/
import React from 'react';
import { Button } from 'reactstrap';

const DefaultButton = ({
  name,
  size,
  onClick,
  disabled,
  className,
  loading,
  block,
  type,
  color,
  value,
}) => (
  <Button
    className={`${className}`}
    onClick={onClick}
    disabled={disabled || loading}
    type={type}
    value={value}
    color={color}
    block={block}
    size={size}>
    {name}
  </Button>
);

DefaultButton.defaultProps = {
  disabled: false,
  className: '',
  loading: false,
  type: 'button',
  color: '',
  block: false,
};

export default DefaultButton;
