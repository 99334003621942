import React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from 'reactstrap';
import Logo from './../../../assets/images/logo.jpg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <header>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img src={Logo} alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {/*<Nav className="ml-auto" navbar>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="/">Home</NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="#">About</NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="#">Partner</NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="#">Programs</NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="#">Blog</NavLink>*/}
            {/*  </NavItem>*/}
            {/*  <NavItem>*/}
            {/*    <NavLink href="#">Contact</NavLink>*/}
            {/*  </NavItem>  */}
            {/*  */}
            {/*</Nav>*/}
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
