/*eslint eqeqeq: "off"*/
import React, { Component } from 'react';
import { connect } from 'react-redux';

export default function(ComposedComponent) {
  class RequireAuth extends Component {
    constructor(props) {
      super(props);
      // console.log('sssssssssssssssss', localStorage.getItem('eventCity'))

      if (localStorage.getItem('eventCity') === null || localStorage.getItem('eventCity') === "") {
        props.history.push('/');
      }
      // else {
      //     var userRoute = window.location.pathname.slice(1, window.location.pathname.indexOf('/', 1));
      //     console.log(userRoute);
      // }
    }

    componentWillUpdate(nextProps) {
      if (localStorage.getItem('eventCity') === null || localStorage.getItem('eventCity') === "") {
        nextProps.history.push('/');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { user: state.authReducer.user };
  }

  return connect(mapStateToProps)(RequireAuth);
}
