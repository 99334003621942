/*eslint eqeqeq: "off"*/
import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { FormGroup, Container, Row, Col, Form } from 'reactstrap';
import {DefaultInput,DefaultButton} from "../../UI/atoms";
import Banner from './../../../assets/images/homepage-banner.png';
import { submitRequestAgencyCode } from "../../../actions/EventActions";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agency_code: "",
      submit_agency_code_data: {}
    }
  }

  componentDidUpdate() {
    const { submit_agency_code_data } = this.state;
    const { submit_agency_code, history } = this.props;

    if (submit_agency_code !== submit_agency_code_data) {
      console.log('submit_agency_code', submit_agency_code)
      if(submit_agency_code && submit_agency_code.data && submit_agency_code.data.value && submit_agency_code.data.value.length > 0) {
        if (submit_agency_code.data.value[0].cr676_location === null ) {
          localStorage.setItem('eventCity', submit_agency_code.data.value[0].owningbusinessunit?.address1_city)
        }
        else {
          localStorage.setItem('eventCity', submit_agency_code.data.value[0].owningbusinessunit?.address1_city)
          // localStorage.setItem('eventCity', submit_agency_code.data.value[0].cr676_location)
        }
        
        localStorage.setItem('agencyCode', submit_agency_code.data.value[0].cr676_agency_code)
        localStorage.setItem('name', submit_agency_code.data.name !== null ? submit_agency_code.data.value[0].name : "")
        history.push('/events');
      }
      this.setState({
        submit_agency_code_data: submit_agency_code
      })
    }
  }

  render() {
    const { agency_code} = this.state
    const { submitRequestAgencyCodeFn } = this.props
    return (
      <div className="homepage">
        <div className="homepage-banner">
              <div className="homepage-banner-left">
                <img src={Banner} alt="" />
              </div>
              <div className="homepage-banner-right">
                <h2>
                  <span>Get Access to</span> EXPERIENCES <span>near you.</span>
                </h2>

                <h5>Please enter your agency code to view available experiences. </h5>
                  <Form onSubmit={(e) => {
                          e.preventDefault();
                          submitRequestAgencyCodeFn({
                            agency_code
                          })
                        }}>
                  <FormGroup >
                    <DefaultInput 
                      placeholder="Enter Agency Code"
                      className="form-custom"
                      name="agency_code"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value
                        })
                      }}
                      value={agency_code}
                      />
                      <DefaultButton
                        name="Go To Events"
                        type="submit"
                        color="link"
                        block={true}
                        // onClick={() => {
                        //   submitRequestAgencyCodeFn({
                        //     agency_code
                        //   })
                        // }}
                      />
                  </FormGroup>
                  </Form>
                <p>The experiences and opportunities in this portal range from tickets to local events, recreational activities, and more and have been provided by Kids Up Front and their generous donors. Your request will go to the agency whose code you are using to login and if approved by them, will be forwarded to Kids Up Front. These opportunities are meant for kids, youth, and their families to bond and have fun and enriching experiences together.</p>
              </div>
        </div>
        <div className="homepage-content">
          <Container>
            <Row>
              <Col lg="7">
                <h3><span>Welcome to the Kids Up Front</span> Experience Hub</h3>
                <p> At Kids Up Front, we believe in the importance of kids having healthy, fun-filled childhoods - for their sake, and for the same of our communities. The Experience Hub is a place where you can find countless opportunities to have FUN with your kids. </p>
              </Col>

              <Col lg="5">
                <div className="important-box">
                  <h4>Important </h4>
                  <ul>
                    <li>These opportunities are for youth 18 and under.</li>
                    <li>Chaperones are able to attend as long as there are always the same or more kids than adults.</li>
                    <li>Your request will be reviewed by your agency and by Kids Up Front. You will receive a confirmation email with details after it is reviewed. </li>
                    <li>Unused opportunities must be returned to Kids Up Front prior to the event so they can be placed with another agency.</li>
					<li>Selling or exchanging these experiences is strictly prohibited.</li>
                  </ul>
                </div>
              </Col>
              
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  submit_agency_code: state.eventReducer.submit_agency_code,
});

const mapDispatch = (dispatch) => ({
  submitRequestAgencyCodeFn: postRequest => dispatch(submitRequestAgencyCode(postRequest))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Landing));
